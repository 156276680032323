.greencontainer {
    padding-bottom: 80px;
    padding-top: 0;
    /* background: linear-gradient(94.59deg, #0e8e60 2.39%, #00b05a 97.66%); */
    background:#0E8E60;
    border-radius: 0px 0px 200px 0px;
  }
.homebtn1{
    color: #0E8E60 !important;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 25px;
background-color: #FFF !important;
  }
  .homebtn1:hover{
    color: #0E8E60 !important;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 25px;
background-color: #FFF !important;
  }
  .homebtn10{
    color: #0E8E60 !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 15px;
    background: #FFF;
    white-space: nowrap;
  }
  .homebtn10:hover{
    color: #0E8E60 !important;
    background: #FFF !important;
    border: 0 !important;
  }
  .homebtn2{
    color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius: 25px;
border: 1px solid #FFF;
  }
  .homebtn2:hover{
    color: #FFF !important;
    background: #0E8E60 !important;
    border: 1px solid #FFF !important;
  }
  .homebtn20{
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 15px;
    border: 1px solid #FFF;
    white-space: nowrap;

  }
  .homebtn20:hover{
    color: #0E8E60 !important;
    background: #FFF !important;
    border: 0 !important;
  }
  .list-icon-header{
    color: #06241B;

/* Header/Heading - 3 */
font-family: Public Sans;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 40px; /* 125% */
letter-spacing: 0.24px;
  }
  .homecountdesc{
    color: #52565C;

/* Body/Large/400 */
margin-top: 0;
font-family: Public Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 140% */
  }
  .homecoursehead{
    color: #000;
text-align: left;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 62px; /* 140.909% */
letter-spacing: -0.088px;
  }
  .homecoursehead0{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 42px; /* 387.5% */
letter-spacing: -0.032px;
  }
  .homecourseframework{
    color: #666768;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.029px;
border-radius: 7px;
background: #EAEAEA;
  }
  .homecourseframework0{
    color: #666768;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.014px;
border-radius: 5px;
background: #EAEAEA;
  }
  .homecoursename{
    color: #000;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.036px;
  }
  .homecoursename0{
    color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.024px;
  }
  .homecorsementor{
    color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.029px;
  }
  .homecorsementor0{
    color: #000;
font-family: Poppins;
font-size: 9px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.019px;
  }
  .homecoursefullDetails{
    color: #0E8E60 !important;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.029px;
border-radius: 6px;
border: 1px solid #00B05A !important;
  }
  .homecoursefullDetails:hover{
    color: #FFF ;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.029px;
border-radius: 6px;
background-color: #0E8E60;  }
.homecoursefullDetails:focus{
  box-shadow: none;
}
  .homecoursefullDetails0{
    border-radius: 6px;
border: 0.5px solid #0E8E60 !important;
color: #0E8E60 !important;
font-family: Poppins;
font-size: 8px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.014px;
  }
  .homecoursefullDetails0:hover{
    color: #FFF ;
    background-color: #FFF;
  }
  .homecoursefullDetails0:focus{
    box-shadow: none;
  }
  .homecoursefee{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.048px;
  }
  .homecoursefee0{
    color: #0E8E60;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.029px;
  }
  .homeSeeMorebtn{
    color: #0E8E60;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.041px;
  }
  .splide__pagination__page.is-active {
    /* background: #fff; */
    /* transform: scale(1.4); */
    z-index: 1;
    /* width: 15px; */
    margin-left: 8px;
    margin-right: 8px;
    width: 28px;
    height: 8px;
    border-radius: 6px;
    background: #0E8E60;
}
  .splide__arrow--next {
    background-color: #0E8E60 !important;

  }
  .splide__arrow--prev{
    background-color: #0E8E60 !important;
    color: #fff;
  }
  /* .splide__pagination {
    bottom: 1em;
    left: 0;
    padding: 0 1em;
    position: absolute;
    right: 5em;
    z-index: 1;
    top: 15em;
    border: 1px solid red;
} */
  .homestudent{
    color: var(--2nd-primary-900, #21212F);

/* Display */
font-family: Public Sans;
font-size: 50px;
font-style: normal;
font-weight: 600;
line-height: 72px; /* 128.571% */
  }
  .homestudent0{
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.038px;
  }

  .homefeedback{
    color: #0E8E60;

/* Display */
font-family: Public Sans;
font-size: 56px;
font-style: normal;
font-weight: 600;
line-height: 72px;
  }
  .homefeedback0{
    color: #0E8E60;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.038px;
  }
  .feedbacktext{
    color: var(--gray-500, #6D737A);

/* Body/Large/400 */
font-family: Public Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 140% */
  }
  .feedbacktext0{
    color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
margin-top: 0;
  }
  .homefeatureshead{
    color: #000;
text-align: center;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 62px; /* 140.909% */
letter-spacing: -0.088px;
  }
  .homefeatureshead0{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 19.5px; /* 59.375% */
text-align: start;
  }
  .homefeatureshead2{
    color: #000;
text-align: center;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.032px;
margin-left: 29.5%;
  }
  .homefeatureshead20{
    color: #000;
text-align: justify;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .homefeaturehead3{
    color: #000;
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.04px;
  }
  .best-grow-head{
    color: #000;
text-align: center;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 62px; /* 140.909% */
letter-spacing: -0.088px;
  }
  .best-grow-head0{
    color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 9.5px; /* 59.375% */
  }
  .best-grow-paragraph{
    color: #666768;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 34px; /* 170% */
  }
  .best-grow-paragraph0{
    color: #000;
text-align: justify;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .best-grow-learnMore{
    border-radius: 12px;
background: #0E8E60;
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.043px;
border: 0;
  }
  .best-grow-learnMore:hover{
  
background: #FFF !important;
color: #0E8E60 !important;
border: 1px solid #00B05A !important;

  }
  .best-grow-learnMore0{
    border-radius: 6px;
background: #0E8E60;
color: #FFF;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.024px;
border: 0;
  }
  .best-grow-learnMore0:hover{
  
    background: #FFF !important;
    color: #0E8E60 !important;
    border: 1px solid #00B05A !important;
    
      }
  .paragraph0_m{
    color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .list0{
    /* text-indent: 4em; */
    text-align: start;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0em;
   
  }
  .list1{
    /* text-indent: 4em; */
    text-align: start;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: grey !important;
  
  }
  .checkbtntltp{
font-size: 18px;
  }
  .checkbtntltp:hover{
    color: #fff !important;
    background-color: #0F8E61 !important;
  }

  .tabcrslboxol {
    height: 340px;
    /* height: 50vh; */
    border-radius: 20px;
    border: 2px solid #d9d9d9;
    background: #FFF;
}