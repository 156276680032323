.coursbuybtn{
  border-radius: 6px;
    border: 1px solid #00B05A;
    background-color: #fff;
    color: #0E8E60;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.029px;
}
.firstImageDiv{
  width: 259px;
height: 380px;
flex-shrink: 0;
border-radius: 1000px;
border: 1px solid #0E8E60;
background: #FFF;
}
.firstImageDiv0{
  height: 210.039px;
  flex-shrink: 0;
  border-radius: 1000px;
border: 1px solid #0E8E60;
background: #FFF;
}
.fID0 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.offlinetext1{
  color: #0E8E60;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 34.8px */
}
.offlinetext1_0{
  color: #0E8E60;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 145%; /* 34.8px */

}
.offlinetext2{
  color: #000;
text-align: center;
font-family: Inter;
font-size: 50px;
font-style: normal;
font-weight: 600;
line-height: 52px; /* 67.5px */
/* border: 1px solid red; */
}
.offlinetext2_0{
  color: #000;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 67.5px */
}
.offlinetext2_green{
  color: #0E8E60;
font-family: Inter;
font-size: 50px;
font-style: normal;
font-weight: 600;
line-height: 52px;
}
.offlinetext2_0_green{
  color: #0E8E60;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 115%;
}
.offlinetext3{
  color: #000;
text-align: center;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 34.8px */
}
.offlinetext3_0{
  color: #000;
text-align: center;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 34.8px */
}
.offlineexplbtn{
  border: 0;
  border-radius: 25px;
background: #0E8E60;
/* width: 180px;
height: 44px; */
/* flex-shrink: 0; */
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
align-self: center;
}
.offlineexplbtn0{
  border: 0;
  border-radius: 10px;
background: #0E8E60;
width: 138px;
height: 24px;
flex-shrink: 0;
color: #FFF;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
}
.offlinetext4{
  color: #0E8E60;
text-align: left;
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
}
.offlinetext4_0{
  color: #0E8E60;
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}
.offlinetext4_2{
  color: #000;
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px;
letter-spacing: -0.72px;
}
.offlinetext4_2_0{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 44px;
letter-spacing: -0.72px;
}
.offlineprgmcrs1{
  color: #0E8E60;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 183.333% */
  letter-spacing: -0.48px;
}
.offlineprgmcrs1_0{
  color: #0E8E60;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 183.333% */
  letter-spacing: -0.48px;
}
.offlineprgmcrs2{
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 220% */
  letter-spacing: -0.4px;
}
.offlineprgmcrs2_0{
  color: #000;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 220% */
  letter-spacing: -0.4px;
}
.offlinecoursediv{
  width: 290px;
height: 360px;
flex-shrink: 0;
border-radius: 20px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.offlinecoursediv_0{
  width: 210px;
  height: 320px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.offlinecoursedivone{
  border-radius: 20px 20px 0px 0px;
width: 290px;
height: 179px;
flex-shrink: 0;
}
.offlinecoursedivone img{
  width: 100%;
  height: 100%;
}
.offlinecoursedivone_0{
  border-radius: 20px 20px 0px 0px;
width: 210px;
height: 160px;
flex-shrink: 0;
}

.offlinecoursedivone_0 img{
  width: 100%;
  height: 95%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.offlinecllgname{
  color: #0E8E60;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.029px;
}
.offlinecllgrating{
  color: #263238;
font-family: Poppins;
font-size: 7px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.017px;
}
.offlinecllgcourse{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.038px;
}
.offlinecllgcourse0{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.038px;
}
.offlinecoursefee{
  color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.043px;
}
.offlinecourseduration{
  color: #0E8E60;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.029px;
}
.offlinecllgcrsbtn{
  color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.029px;
border: 0;
border-radius: 5px;
background: #0E8E60;
/* width: 262px; */
height: 24px !important;
flex-shrink: 0;
}
.offlinecllgcrsbtn_0{
  color: #FFF;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.029px;
  border: 0;
  border-radius: 5px;
  background: #0E8E60;
  /* width: 262px; */
  height: 24px !important;
  flex-shrink: 0;
}
.offlinetext8{
  color: #000;
text-align: start;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 44px; /* 183.333% */
letter-spacing: -0.48px;
}
.offlinetext8_0{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 183.333% */
letter-spacing: -0.48px;
}
.offlinetext9{
  color: #000;
text-align: start;
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
}
.offlinetext9_0{
  color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 122.222% */
letter-spacing: -0.72px;
}
.offlinetext9_2{
  color: #0E8E60;
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px;
letter-spacing: -0.72px;
}
.offlinetext9_2_0{
  color: #0E8E60;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px;
letter-spacing: -0.72px;
}
.offlinetext11{
  color: rgba(0, 0, 0, 0.70);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 34.8px */
text-align: start;
width: 596px;
}
.offlinetext11_0{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 34.8px */
text-align: start;
width: 596px;
}
.offlinefreebtn{
  color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 144.444% */
letter-spacing: -0.36px;
width: 200px;
height: 50px;
flex-shrink: 0;
border-radius: 5px;
background: #0E8E60;
border: 0;
display: flex;
justify-content: center;
align-items: center;
}
.offlinefreebtn_0{
  color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 26px; /* 144.444% */
letter-spacing: -0.36px;
width: 150px;
height: 30px;
flex-shrink: 0;
border-radius: 3px;
background: #0E8E60;
border: 0;
display: flex;
justify-content: center;
align-items: center;
align-self: center;
}
.offlinereviews{
  height: 320px;
  background: #0E8E60;
}
.offlinereviews_0{
  height: 186px;
  background: #0E8E60;
}
.crcl{
  width: 480px;
height: 480px;
flex-shrink: 0;
border-radius: 50%;
border: 3px solid #EAEAEA;
}
.usernaam{
  color: #FFF;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: start;
}
.usernaam_0{
  color: #FFF;
  text-align: start;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
}
.userdesg{
  color: #FAFAFA;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
}
.userdesg_0{
  color: #FAFAFA;
  text-align: start;
font-family: Poppins;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
}
.userdesc{
  text-align: start;
  color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 26px; /* 162.5% */
}
.userdesc_0{
  text-align: start;
  color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: 15px; /* 162.5% */
}
.splide__pagination__page {
  background: #0E8E60 !important;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: .7;
  padding: 0;
  position: relative;
  transition: transform .2s linear;
  width: 8px;
}
.qstnshrtdesc{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 156%; /* 24.96px */
text-align: start;
}
.qstnshrtdesc_0{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 156%; /* 24.96px */
text-align: start;
}
/* .p-accordion .p-accordion-header .p-accordion-header-link{
  border-radius: 8px !important;
background: #0E8E60 !important;
} */
.acrhdroffline{
  color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
}
.acrhdroffline_0{
  color: #0E8E60;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
text-align: start !important;
}
.custom-expand-iconoffline {
  border: 0;
  background-color: #0E8E60;
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  margin-top: -8px;
  position: absolute;
  right: 0.5em;
  top: 40%;
}
.accordion-taboffline{
  margin-bottom: 15px;
}