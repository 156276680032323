.primary-color{
    /* background-image: linear-gradient(94.59deg, #0E8E60 2.39%, #00B05A 97.66%); */

}
.p-menu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    /* background: #EEF2FF; */
    background-color: #c2d4cd !important;
    box-shadow: none;
}
.p-menu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #0E8E60 !important;
}
.p-menu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #0E8E60 !important;
    font-weight: 500;
}
.p-menu.p-menu-overlay {
    background: #ffffff;
    border: 0 none;
    border-radius: 5px;
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.1);
}
.sidebarofmobile{
    background-color: var(--greenn-500);
}
.p-sidebar-close{
    color: white;
}
.navdiv{
    background-color: #0E8E60;
}
.navdiv2{
background-color: #FFF;
color: #0E8E60 !important;
}
.headers{
    color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.headers2{
    color: #0E8E60;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.signupbtn{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 5px;
background: #FFF !important;
border: 0;
}
.signupbtn2{
    color: #FFF;
    background-color: #0E8E60 !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 5px;
    background: #FFF;
    border: 0;
}


@media only screen and (max-width: 760px) {

    .p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text, .p-fileupload-choose.p-button-success.p-button-text {
        background-color: transparent;
        color: #0E8E60;
        border-color: transparent;
    }
    .p-menu .p-menuitem-link .p-menuitem-text {
        color: black ;
        font-size: 14px ;
        font-weight: 400;
    }
    .p-menu .p-menuitem-link {
        padding: 0.45rem 1.25rem;
        color: black ;
        border-radius: 0;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
        user-select: none;
        font-family: "poppins";
    }
    .lftmnu{
        z-index: 2102;
        transform-origin: center top;
        /* top: 265px !important; */
        left: 50% !important;
    }
    .smalllftmenu{
        /* width: 206px; */
     border: 1px solid red;
    }
}

.p-tieredmenu .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    color: black;
    border-radius: 0;
    transition: box-shadow 0.2s;
    -webkit-user-select: none;
    user-select: none;
}