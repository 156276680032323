/* .p-dialog .p-dialog-header .p-dialog-title {
    color: #0E8E60 !important;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
} */
/* .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #343a40;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .1rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
} */
.dgdesc{
    color: rgba(48, 48, 48, 0.75);
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 3px; /* 25.2px */
}
.lbl{
    color: #000 ;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 5px;
}
.lblimp{
    color: #0E8E60;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
}
/* .dgsignupbtn{
    border-radius: 10px;
border: 1px solid rgba(0, 0, 0, 0.21);
background: #0E8E60;
display: flex;
padding: 11px 0px;
justify-content: center;
align-items: center;
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
} */
::-webkit-scrollbar {
    display: none;
}

input[type=tel] {
box-shadow: none;
&:active, &:focus { 
    /* font-size: 13px; */
    /* border: 2px solid red; */
    outline: none;
    background-color: #ffffff;
  }
}
@media only screen and (max-width: 760px) {
    .lbl{
        font-size: 12px;
    }
    .p-inputtext {
        font-family: Poppins !important;
        font-size: 0.85rem;
        color: #000 !important;
        background: #ffffff;
        padding: 0.75rem 0.75rem;
        border: 1px solid #ced4da;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px;
    }
    }