/*  */
/* .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #343a40;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .1rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
} */
.dgdesc{
    color: rgba(48, 48, 48, 0.75);
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 3px; /* 25.2px */
}
.lbl{
    color: #000 ;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 5px;
}
.lblimp{
    color: #0E8E60;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
}
/* .dgsignupbtn{
    border-radius: 10px;
border: 1px solid rgba(0, 0, 0, 0.21);
background: #0E8E60;
display: flex;
padding: 11px 0px;
justify-content: center;
align-items: center;
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
} */
::-webkit-scrollbar {
    display: none;
}
.dgsignupbtn:hover{
    background-color: #0E8E60 !important;
}
#username:-webkit-autofill,
#username:-webkit-autofill:hover, 
#username:-webkit-autofill:focus, 
#username:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-background-color: #ffffff;
    /* transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329; */
}
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* input:focus{
    box-shadow: none;
    border: 1px solid #0E8E60 !important ;
} */

.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0 2rem 2rem 2rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border: 0 none;
    color: #000 !important;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}
@media only screen and (max-width: 760px) {
.lbl{
    font-size: 12px;
}

}