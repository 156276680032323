.acrhdrnew{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;   
}
.sidehrd{
    color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 156%; /* 43.68px */
text-align: start;
}
/* .p-accordion-header{
    border-radius: 0px;
} */
/* .p-accordion .p-accordion-header .p-accordion-header-link {
    transition: none;
    border: 0;
    border-radius: 0;
} */
.vidCont video{
    height: 100%;
}
.accordion-tab .p-accordion-header-link {
    min-height: 65px !important;
    /* border: 1px solid red !important; */
    border-radius: 0 !important;
    /* border: 0 !important; */
    background-color: #FFF !important;
}
.p-accordion .p-accordion-tab {
margin-top: 0;
margin-bottom: 0;

}
.p-toggleable-content {
    border-radius: 0 !important;
    border: 0 !important;
    background-color: #f8f9fa !important;
    padding: 0 !important;
    /* overflow: scroll !important;
    border: 1px solid green; */
}
.p-panel .p-panel-content {
     padding: 0 !important;
     /* overflow: scroll !important;
     border: 1px solid red; */
}
.p-panel .p-panel-header {
background-color: #fff !important;
z-index: 1000;
border-radius: 0 !important;
position: sticky;
top: 0;

}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #0E8E60;
    background: #0E8E60;
    box-shadow: none !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
    box-shadow: none !important;
    border-color: grey !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{
    background-color: #0E8E60;

}
/* class="p-toggleable-content p-accordion-header accordion-tab p-toggleable-content-enter-done" */
/* .p-accordion .p-accordion-content {
    padding: 1.25rem;
    border: 0;
    background: #ffffff;
    color: #495057;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

  } */
  .dldbtn:focus{
    box-shadow: none;
    background-color: #0E8E60;
    border: none;
    color: #FFF;
  }
  .dldbtn{
    border-radius: 25px;

    background-color: #FFF;
    border: 1px solid #0E8E60;
    color: #0E8E60;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .dldbtn2{
    border-radius: 25px;
    background-color: #0E8E60;
    border: none;
    color: #FFF;
   
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  /* .p-toggleable-content {

overflow: scroll !important;
height: 100%;
  } */