.p-dialog .p-dialog-header .p-dialog-title {
    color: #0E8E60 !important;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-right: 15px;
/* border: 1px solid; */
}
.p-dialog {
    /* width: 420px; */
    border-radius: 15px !important;
}
.p-dialog .p-dialog-content:last-of-type  {
border-bottom-left-radius: 15px;
border-bottom-right-radius: 15px;
}
.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #343a40;
    padding-left:  2rem;
    padding-right: 2rem;
    padding-top: 0.8rem;
    padding-bottom: .1rem;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}
.dgdesc{
    color: rgba(48, 48, 48, 0.75);
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 3px; /* 25.2px */
}
.lbl{
    color: #000 ;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 5px;
}
.lblimp{
    color: #0E8E60;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
}
.dgsignupbtn{
    border-radius: 25px;
border: 1px solid rgba(0, 0, 0, 0.21);
background: #0E8E60;
display: flex;
padding: 11px 0px;
justify-content: center;
align-items: center;
color: #FFF !important;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.dgsignupbtn:focus{
    box-shadow: none;
}
::-webkit-scrollbar {
    display: none;
}
.loginbtnclr{
  
    background-color: #FFF;
    border: none;
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 25px;
}
.loginbtnclr2{
    border-radius: 25px;
    background-color: #0E8E60;
    border: none;
    color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.loginbtnclr:hover{
    background-color: #FFF !important;
    color: #0E8E60 !important;
}
.loginbtnclr:focus{
    box-shadow: none;
}
@media only screen and (max-width: 760px) {
.lbl{
    font-size: 12px;
}
}