.footerlogo{
    color: #FFF;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-align: start;
}
.footerlogo0{
    color: #FFF;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: start; 
}
.footerdesc{
    color: #FFF;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
}
.footerdesc0{
    color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;

}
.footerhead{
    color: #FFF;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.04px;
text-align: start;
}
.footerhead0{
    color: #FFF;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.03px;
}
.footerbody{
    color: #FFF;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
}
.footerbody0{
    color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.footerlast{
    color: #BDB9B9;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.24px;
}