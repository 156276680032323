/* .contactusform{
    min-height: 900px;
   
} */
.cntctlbl3{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 33.6px */
}
.cntctlbl2{
    color: #0E8E60;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 38.4px */
}
.subbtn{
    color: #0E8E60;
font-family: Inter;
font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 43.2px */
}
.chck{
    font-family: "poppins";
    font-size: 14px;
    font-weight: 400;
}
.chck:focus{
    outline: none;
}
.headphoneimage{
    width: 400px;
height: 563.636px;
flex-shrink: 0;
border-radius: 0px 125px;
background: #0E8E60;}
.headphoneimage img{
    height: 100%;
    overflow: hidden;
}
.p-inputtext{
    /* background-color: #0E8E60 !important;
    padding-left: 0px; 
    color: #ffffff;
     border: 0px; */
}
.inputformelon{
    /* background-color: #0E8E60 !important; */
    padding-left: 0px; 
    /* color: #ffffff; */
     border: 0px;
}
.p-progress-path{
    color: #0E8E60;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem #0E8E60;
    border-color: #0E8E60;
  }
  .p-inputtext:enabled:hover {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.05rem #0E8E60;
    border-color: #0E8E60;
  }
.contactus{

font-size: 78px;
font-weight: 400;
line-height: 93px;
letter-spacing: 0em;
text-align: left;

}
.formcontainer{
    height: 75%;
}
/* .inputformel{
    background-color: transparent;
    border: 1px;
    outline: none;
    height: 33px !important;
    width: 70%;
} */
.label{
    font-family: "Poppins";
    font-size: 20px;
 
    /* color: white; */
font-style: normal;
line-height: 31px;
letter-spacing: 0em;
text-align: left;

}
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
/* input[type="text"]{
    background-color:#0E8E60;
} */
.inputformel:-webkit-autofill,
.inputformel:-webkit-autofill:hover, 
.inputformel:-webkit-autofill:focus, 
.inputformel:-webkit-autofill:active{
    -webkit-background-clip: text; 
     /* -webkit-text-fill-color: #ffffff;  */
     transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

@media screen and (max-width:768px){
    .contactusform{
        min-height: 700px;
    }
}
.contactlabel{
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}