.inshead{
    font-family: Poppins;
    font-size: 75px;
    font-weight: 500;
}
.inshead0{
    font-family: Poppins;
    font-size: 65px;
    font-weight: 500;
}
.inspgdesc{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.424px;
    line-height: 155%; /* 26.1px */

}
.insformhead{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    color: #0E8E60;
    text-transform:uppercase
}
.insformsubhead{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
}