.imgdiv{
    border-radius: 10px;


    
}
.redrctbutton1{
    background-color: #0E8E60 !important;
    border: 1px solid #0E8E60;
    color: #fff !important;
font-family: Poppins !important;
font-size: 20px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 130%;  
transition: none !important;
box-shadow: none !important;

}
.redrctbutton1:hover{
    border: 0;
}
.redrctbutton1_0{
    background-color: #0E8E60 !important;
    border: 1px solid #0E8E60;
    color: #fff !important;
font-family: Poppins !important;
font-size: 9px !important;
font-style: normal !important;
font-weight: normal !important;
line-height: 130%;  
transition: none !important;
box-shadow: none !important;

}
.redrctbutton1_0:hover{
    border: 0;
}
.redrctbutton{
    background-color: #fff !important;
    border: 1px solid #0E8E60;
    color: #0E8E60 !important;
font-family: Poppins !important;
font-size: 20px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 130%; 
transition: none !important;
box-shadow: none !important;
}
.redrctbutton:hover{
    background-color: #0E8E60 !important;
    border: 0;
    color: #fff !important;
font-family: Poppins !important;
font-size: 20px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 130%; 
}
.redrctbutton_0{
    background-color: #fff !important;
    border: 1px solid #0E8E60;
    color: #0E8E60 !important;
font-family: Poppins !important;
font-size: 8px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 130%; 
transition: none !important;
box-shadow: none !important;
}
.redrctbutton_0:hover{
    background-color: #0E8E60 !important;
    border: 0;
    color: #fff !important;
font-family: Poppins !important;
font-size: 8px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 130%; 
}
.phheading{
    color: #0E8E60;
text-align: center;
font-family: Poppins !important;
font-size: 45px;
font-style: normal;
font-weight: 500;
line-height:normal; /* 80% */
}
.phheading0{
    color: #0E8E60;
text-align: center;
font-family: Poppins !important;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height:normal; /* 80% */
}
.phlist{
    color: rgba(0, 0, 0, 0.65);
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 120% */
text-align: left;
}
.phlist0{
    color: rgba(0, 0, 0, 0.65);
font-family: Poppins;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 120% */
text-align: left;
}