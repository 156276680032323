.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: rgb(14, 142, 96);
}
  
#change{
    border:"none !important"
}
.crd{
    width: 290px;
    height: 377px;
    flex-shrink: 0;
    border-radius: 20px;
    /* border: 0.5px solid rgb(238, 234, 234) !important; */
    background-color: rgb(255, 255, 255);
    margin-right: 45px;
    border: 1px solid red;
}