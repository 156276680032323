.pricesummary{
    color: #FFF;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 48.6px */
}
.pricesummary0{
    color: #0E8E60;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 27px */
}
.orgpricetext{
    color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 27px */
}
.orgpricetext0{
    color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
}
.orgpricenumber{
    color: #FFF;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.048px;
}
.orgpricenumber0{
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
line-height: normal;
letter-spacing: -0.048px;
}
.checkoutline{
    background: #BDB9B9;
}
.checkoutline0{
    background: #BDB9B9;
}
.totlpricetext{
    color: #FFF;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 32.4px */
}
.totlpricetext0{
    color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 21.6px */
}
.totlpricenumber{
    color: #FFF;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.058px;
}
.totlpricenumber0{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.058px;
}
.proceeddesc{
    color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: left;
}
.proceeddesc0{
    color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: left;
}
.proceedbtn{
    color: #0E8E60;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 5px;
border: 1px solid #FFF;
background: #FFF;
display: inline-block;
align-items: center;
}
.proceedbtn:hover{
    color: #0E8E60 !important;
    background: #FFF !important;
    border: 0 !important;
}
.proceedbtn0{
    color: #fff;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 5px;
/* border: 1px solid #FFF; */
background: #0E8E60;
display: inline-block;
align-items: center;
}
.checkoutttl1{
    color: #0E8E60;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 48.6px */
}
.checkoutttl10{
    color: #0E8E60;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 27px */
}
.checkoutttl2{
    color: #000;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 135%;
}
.checkoutttl20{
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
}
.checkoutformhead{
    color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 37.8px */
}
.checkoutformhead0{
    color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 24.3px */
}
.checkoutlbl{
    color: #0E8E60;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 24.3px */
}
.checkoutlbl0{
    color: #0E8E60;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 18.9px */
}
.formfooterdesc{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
}
.formfooterdesc0{
    color: #000;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
}
.checkout_method{
    color: #000;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 37.8px */
}
.checkout_method0{
    color: #000;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 24.3px */
}
.cheeckout_conn{
    color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 21.6px */
}
.cheeckout_conn0{
    color: #000;
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 13.5px */
}
.checkoutacctab{
    /* height: 40px; */
border-radius: 0rem !important;
margin-bottom: 0rem !important;
margin-top: 0rem !important;
padding-top: 0rem !important;
padding-bottom: 0rem !important;
border-radius: 0rem !important;

}
.checkoutacc{
    display: flex;
    flex-direction: column;
    gap: 0 !important;
border-radius: 0rem;
/* border: 1px solid red; */
}
.checkoutacrhdr{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;

}
.custom-icon{
    /* border: 1px solid; */
    /* background-color: #0E8E60; */
padding-left: 0;
width: 40px;  
margin-right: 45px !important;
/* color: #fff; */
    /* border-radius: 50%; */
    /* width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center; */
    
    /* padding: 8px; */
}
/* .accordion-container{
    background-color: #0E8E60;
} */
.p-check90-link{
    background-color: #0E8E60;
}
/* .p-accordion-container .p-accbd .p-accbd-link {
background-color: #0E8E60;
} */
.checkoutlbl2{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.upi1{
    border-radius: 5px;
border: 0.5px solid #0E8E60;
background: #FFF;
display: flex;
justify-content: center;
align-items: center;
padding-left: 2px;
padding-right: 2px;
gap: 2px;
height: 50px;
}
.upi2{
    border-radius: 3px;
border: 0.5px solid #0E8E60;
background: #0E8E60;
height: 40px;
color: #FFF;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
}
.upi3{
    border-radius: 3px;
border: 0.5px solid #0E8E60;
background: #FFF;
height: 40px;
color: #0E8E60;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
}
.card2 {
    height: calc(100vh - 80px); 
    overflow-y: scroll;
  }
  
  .card2::-webkit-scrollbar {
    width: 0.5em;
    /* background-color: #F5F5F5; */
  }


  .p-accordion-header-text {
    width: 90%;
    line-height: 1;
    /* border: 1px solid red; */
}