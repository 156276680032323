#crthdg1{
    color: #1C1C1C;
font-size: 61.598px;
font-family: Poppins;
font-weight: 600;
line-height: 117.5%;
letter-spacing: -0.924px;
}
#crthdg1_0{
    color: #1C1C1C;
    font-size: 41.598px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 117.5%;
    letter-spacing: -0.924px;
}
#lbl{
    color: #0E8E60;
font-size: 29.799px;
font-family: Poppins;
font-weight: 500;
line-height: 29.53px;
letter-spacing: 0.621px;
}
input::file-selector-button{
    display: none;
}
#filelbl{
    border-radius: 25px;
border: 1.443px solid #000;

}
#crtbtn{
    color: #FFF;
font-size: 18px;
font-family: Poppins;
font-weight: 300;
letter-spacing: -0.48px;
border-radius: 25px;
background: #0E8E60;
height: 44px;
padding-left: 25px;
padding-right: 25px;
padding-top: 8px;
cursor: pointer;
}